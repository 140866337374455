
#id65a9dd84b0184704f908ac47 {
  .gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
#ieu9 {
color : black; 
width : 207px; 
height : 112px; 
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406racilqnu37k1.png'); 
} 
#ikce {
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406racilqnu37k1.png'); 
} 
#idwu {
padding : 10px; 
min-height : 100px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : local; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406racilqnu8lcy.jpg'); 
height : 531px; 
float : none; 
text-align : center; 
} 
#iu5m {
padding : 10px; 
display : flex; 
float : none; 
align-items : flex-end; 
justify-content : space-around; 
} 
#i504 {
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
font-size : 74px; 
color : white; 
text-align : center; 
display : flex; 
} 
#ixrtu {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-self : stretch; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
color : white; 
font-size : 30px; 
} 
#ii1o4 {
height : 350px; 
width : 615px; 
} 
#iwvhh {
color : black; 
float : center; 
display : flex; 
align-self : flex-end; 
align-items : flex-end; 
justify-content : space-around; 
margin : 42px 0px 0px 0px; 
} 
#ipqrw {
padding : 10px; 
display : flex; 
float : none; 
align-items : flex-end; 
justify-content : space-around; 
} 
#iowmr {
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
font-size : 74px; 
color : white; 
text-align : center; 
display : flex; 
} 
#il8xn {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-self : stretch; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
color : white; 
font-size : 30px; 
float : center; 
position : relative; 
align-items : flex-end; 
text-align : center; 
} 
#i8iut {
padding : 10px; 
min-height : 100px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : local; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406racilqnu8lcy.jpg'); 
height : 531px; 
float : none; 
} 
#ic7o7 {
padding : 10px; 
min-height : 100px; 
} 

  
  @media only screen and (max-width: 480px) {#iwvhh {
float : none; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#i504 {
display : block; 
font-size : 31px; 
font-family : Arial, Helvetica, sans-serif; 
color : white; 
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#idwu {
float : none; 
height : 379px; 
}} 
@media only screen and (max-width: 480px) {#ii1o4 {
width : 142px; 
height : 135px; 
}} 
@media only screen and (max-width: 480px) {#iowmr {
display : block; 
font-size : 30px; 
}} 
@media only screen and (max-width: 480px) {#i8iut {
float : none; 
height : 394px; 
}} 
@media only screen and (max-width: 480px) {#ixrtu {
padding : 0px 10px 10px 10px; 
font-family : Arial, Helvetica, sans-serif; 
font-size : 24px; 
color : white; 
text-align : center; 
display : block; 
justify-content : space-around; 
align-self : center; 
}} 
@media only screen and (max-width: 480px) {#il8xn {
font-size : 20px; 
}} 

}
  