
#id65ab3164b0184704f908b1ee {
  .gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
#i5zdr {
padding : 10px; 
min-height : 100vh; 
display : flex; 
align-items : center; 
} 
#ip5yj {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
display : flex; 
width : 100%; 
align-items : stretch; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
flex-wrap : wrap; 
} 
#invki {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
align-items : center; 
} 
#i454b {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i5tpi {
padding : 10px; 
min-height : 100px; 
} 
#i5al8 {
padding : 10px; 
display : block; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
font-weight : 800; 
color : #34188e; 
font-size : 4rem; 
text-align : center; 
} 
#idyhz {
padding : 10px; 
display : block; 
font-size : 2rem; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
color : #0fe7a9; 
text-align : center; 
} 
#iqygh {
padding : 10px; 
display : block; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
color : #8c716a; 
text-align : center; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.input-veamyhealth {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#dcdcdc 0%, #dcdcdc 100%); 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
padding : 15px 10px 15px 10px; 
border-radius : 15px 15px 15px 15px; 
width : 100%; 
} 
#ibsby {
color : black; 
width : 75%; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 

  @media only screen and (max-width: 992px) {#invki {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ip5yj {
flex-direction : column-reverse; 
}} 
@media only screen and (max-width: 992px) {#i454b {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ibsby {
width : 50%; 
}} 

  @media only screen and (max-width: 480px) {#i5al8 {
font-size : 2rem; 
}} 
@media only screen and (max-width: 480px) {#idyhz {
font-size : 1rem; 
}} 
@media only screen and (max-width: 480px) {#ip5yj {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 480px) {#ibsby {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#invki {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#i5tpi {
padding : 10px 0px 10px 0px; 
}} 

}
  