
#id65a9c0dcb0184704f9089f87 {
  .gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
#ieu9 {
color : black; 
width : 207px; 
height : 112px; 
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406racilqnu37k1.png'); 
transition : width 3s ease; 
} 
#ikce {
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406racilqnu37k1.png'); 
} 
#idwu {
padding : 10px; 
min-height : 531px; 
float : none; 
text-align : center; 
display : flex; 
align-items : center; 
justify-content : center; 
opacity : 1; 
align-self : center; 
color : #d5d5d5; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406racilrtt9wqf.png'); 
flex-direction : column; 
} 
#i504 {
font-family : 'Didact Gothic', sans-serif; 
font-size : 74px; 
color : #2d2547; 
text-align : center; 
display : flex; 
transition : width 5s ease; 
font-weight : 800; 
justify-content : center; 
align-items : center; 
align-self : center; 
letter-spacing : -2px; 
flex-direction : column; 
} 
#ixrtu {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-self : stretch; 
font-family : 'Didact Gothic', sans-serif; 
color : #a0529a; 
font-size : 45px; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : flex; 
background-image : radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
flex : 0 0 0px; 
label-parent-flex : 0; 
order : 0; 
position : relative; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#iniq1 {
padding : 10px; 
min-height : 100px; 
} 
#iv0cj {
width : 989px; 
display : flex; 
flex-direction : column; 
justify-content : space-around; 
align-items : flex-end; 
height : 434px; 
margin : 30px 0px 0px 0px; 
} 
#iv0qk {
color : #d983a6; 
padding : 5px; 
height : auto; 
display : block; 
} 
#idbvk {
width : auto; 
display : flex; 
flex-direction : column; 
justify-content : space-around; 
align-items : center; 
align-self : center; 
color : #fefefe; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
font-size : 25px; 
text-decoration : underline; 
} 
#i09j {
display : flex; 
flex-direction : row; 
justify-content : space-around; 
align-items : center; 
align-self : center; 
} 
#iz34g {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
align-self : stretch; 
flex-direction : column; 
border : 0 solid black; 
margin : -8px 0px -3px 0px; 
opacity : 1; 
} 
#i8zbk {
flex-direction : column; 
justify-content : space-around; 
align-items : center; 
align-self : center; 
color : #fefefe; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
font-size : 25px; 
text-decoration : underline; 
} 
#ihg44 {
color : #d983a6; 
padding : 5px; 
height : auto; 
display : block; 
} 
#ifkp1 {
padding : 10px; 
min-height : 162px; 
display : flex; 
justify-content : center; 
align-self : center; 
align-items : center; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406racilrtt9wqf.png'); 
opacity : 1; 
height : 459px; 
} 
#iq23g {
color : black; 
width : 566px; 
height : 426px; 
display : flex; 
justify-content : flex-start; 
align-items : flex-start; 
align-self : flex-end; 
flex-direction : row; 
transition : opacity 9s linear; 
min-height : 10auto; 
} 
#i0mrl {
padding : 10px; 
display : flex; 
justify-content : flex-start; 
align-items : flex-start; 
align-self : center; 
flex-direction : row; 
margin : 0px 0px 0px -19px; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 39px; 
text-align : left; 
color : #f286ea; 
font-weight : 800; 
} 
#iikjr {
padding : 10px 10px 10px 16px; 
min-height : 100px; 
border : 0 solid black; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406racilry437jn.png'); 
display : flex; 
align-items : flex-end; 
align-self : center; 
justify-content : space-around; 
} 
#iznqp {
color : black; 
width : 575px; 
height : 390px; 
justify-content : center; 
align-items : center; 
align-self : center; 
display : flex; 
} 
#i3ffh {
color : black; 
width : 462px; 
height : 327px; 
align-self : center; 
justify-content : flex-start; 
padding : 0px 0px 0px 0px; 
} 
#iqa5d {
padding : 10px; 
min-height : 100px; 
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406racils10x0kx.png'); 
display : flex; 
justify-content : space-around; 
align-items : center; 
align-self : center; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
color : #ffffff; 
font-weight : 700; 
font-size : 40px; 
height : 32px; 
} 
#iiu5x {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
} 
#i8gv4 {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
height : 36px; 
} 
#iw20m {
padding : 10px; 
display : flex; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 40px; 
color : #5271ff; 
font-weight : 800; 
flex-direction : column; 
justify-content : space-around; 
align-items : flex-start; 
align-self : center; 
} 
#i9gc6 {
color : black; 
width : 331.5px; 
min-height : 10px; 
display : flex; 
justify-content : space-around; 
} 
#ip02x {
color : black; 
width : 332.5px; 
min-height : 10px; 
display : flex; 
} 
#i44sq {
padding : 10px; 
min-height : 441px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406racilry437jn.png'); 
display : flex; 
justify-content : space-around; 
align-items : center; 
font-family : 'Didact Gothic', sans-serif; 
color : #fefefe; 
font-size : 57px; 
text-align : center; 
font-weight : 700; 
flex-direction : column; 
} 
#im389 {
padding : 10px; 
display : block; 
} 
#i8qz5 {
padding : 10px; 
display : block; 
font-weight : 500; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 42px; 
} 
#ial8f {
display : flex; 
justify-content : space-around; 
align-items : center; 
flex-direction : column; 
} 
#ij1lp {
display : flex; 
justify-content : space-around; 
flex-direction : column; 
align-items : center; 
} 
#i2fga {
align-items : center; 
align-self : center; 
justify-content : center; 
flex-direction : row; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#iaz0y {
padding : 10px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(to top, #09203f 0%, #537895 100%); 
cursor : pointer; 
color : #fff; 
} 
#i2p5i {
padding : 10px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(to top, #09203f 0%, #537895 100%); 
cursor : pointer; 
color : #fff; 
} 
#i2til {
padding : 10px; 
min-height : 761px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406racilsa7xd1h.png'); 
border : 0 solid black; 
} 
#i23q4 {
padding : 10px; 
min-height : 856px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406racils10umh3.png'); 
} 

  @media only screen and (max-width: 992px) {#i504 {
font-size : 54px; 
}} 

  @media only screen and (max-width: 480px) {#i504 {
display : block; 
font-size : 31px; 
font-family : 'Didact Gothic', sans-serif; 
color : #2d2547; 
text-align : center; 
margin : 0 0px 0px 0px; 
font-weight : 600; 
letter-spacing : -1px; 
}} 
@media only screen and (max-width: 480px) {#idwu {
float : none; 
padding : 0px 0px 0px 0; 
flex-direction : column; 
min-height : 300px; 
}} 
@media only screen and (max-width: 480px) {#ixrtu {
padding : 0px 10px 10px 10px; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 24px; 
color : #a0529a; 
text-align : center; 
display : block; 
justify-content : space-around; 
align-self : center; 
}} 
@media only screen and (max-width: 480px) {#iv0cj {
flex-direction : row; 
width : 310px; 
height : 183px; 
align-self : center; 
align-items : center; 
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#iz34g {
opacity : 1; 
min-height : 57px; 
width : 310px; 
justify-content : center; 
flex-direction : row; 
align-self : center; 
}} 
@media only screen and (max-width: 480px) {#idbvk {
flex-direction : row; 
font-size : 11px; 
}} 
@media only screen and (max-width: 480px) {#i8zbk {
flex-direction : row; 
font-size : 11px; 
}} 
@media only screen and (max-width: 480px) {#iv0qk {
font-size : 0px; 
}} 
@media only screen and (max-width: 480px) {#ifkp1 {
flex-direction : column; 
height : 318px; 
}} 
@media only screen and (max-width: 480px) {#iq23g {
justify-content : space-around; 
flex-direction : row; 
align-items : center; 
align-self : center; 
height : 216px; 
width : 280px; 
}} 
@media only screen and (max-width: 480px) {#i0mrl {
margin : 0px 0px 0px 0px; 
font-size : 25px; 
color : #a0529a; 
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#iikjr {
flex-direction : column; 
height : 428px; 
}} 
@media only screen and (max-width: 480px) {#iznqp {
width : 305px; 
height : 226px; 
}} 
@media only screen and (max-width: 480px) {#i3ffh {
height : 219px; 
width : 257px; 
}} 
@media only screen and (max-width: 480px) {#iiu5x {
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#iqa5d {
height : 10px; 
}} 
@media only screen and (max-width: 480px) {#iw20m {
font-size : 19px; 
text-align : center; 
justify-content : center; 
align-items : center; 
align-self : center; 
}} 
@media only screen and (max-width: 480px) {#i8gv4 {
justify-content : center; 
height : 51px; 
}} 
@media only screen and (max-width: 480px) {#i8qz5 {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#im389 {
font-size : 31px; 
font-family : 'Didact Gothic', sans-serif; 
}} 
@media only screen and (max-width: 480px) {#i44sq {
min-height : 290px; 
}} 
@media only screen and (max-width: 480px) {#iaz0y {
color : #2d2547; 
__background-type : grad; 
background-image : linear-gradient(to top, #09203f 0%, #537895 100%); 
}} 
@media only screen and (max-width: 480px) {#ihev4 {
font-size : 13px; 
text-align : center; 
font-family : 'Didact Gothic', sans-serif; 
color : #ffffff; 
}} 
@media only screen and (max-width: 480px) {#i7kp5 {
font-size : 13px; 
text-align : center; 
font-family : 'Didact Gothic', sans-serif; 
}} 
@media only screen and (max-width: 480px) {#i23q4 {
min-height : 236px; 
}} 
@media only screen and (max-width: 480px) {#i2til {
min-height : 229px; 
}} 

}
  